import "./ctPartUsageNew.scss";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
import { useCtPartUsage } from "../../contexts/ctPartUsage";
// api
import { uploadJson, setDeliveredPurchaseRequest } from "../../api/auth";
// devextreme
import { alert } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";

export default function CtPartUsageNew() {
  const { user } = useAuth();
  const ctPartUsage = useCtPartUsage();
  const navigate = useNavigate();

  const [type, setType] = useState("");
  const [date, setDate] = useState("");
  const [registrant, setRegistrant] = useState("");
  const [itemId, setItemId] = useState("");
  const [itemName, setItemName] = useState("");
  const [quantity, setQuantity] = useState("");
  const [note, setNote] = useState("");
  const [tag, setTag] = useState("");

  useEffect(() => {
    let userId: string = "";
    let userName: string = "";
    if (user) {
      userId = user.id;
      userName = user.name;
    }

    // context 변수로부터 element에 복사
    setType(ctPartUsage.type);
    setDate(ctPartUsage.date);
    setRegistrant(ctPartUsage.registrant);
    setItemId(ctPartUsage.itemId);
    setItemName(ctPartUsage.itemName);
    setQuantity("0");
    setNote("");
    setTag(ctPartUsage.tag);
  }, []);

  function onTypeTextBoxValueChanged(e: string) {
    setType(e);
  }
  function onDateTextBoxValueChanged(e: string) {
    setDate(e);
  }
  function onRegistrantTextBoxValueChanged(e: string) {
    setRegistrant(e);
  }
  function onItemIdTextBoxValueChanged(e: string) {
    setItemId(e);
  }
  function onItemNameTextBoxValueChanged(e: string) {
    setItemName(e);
  }
  function onQuantityTextBoxValueChanged(e: string) {
    setQuantity(e);
  }
  function onNoteTextBoxValueChanged(e: string) {
    setNote(e);
  }

  //////////////// Upload Button
  async function onUploadButtonClicked() {
    // 수량 이상 체크
    let quantityN: number = parseInt(quantity);
    if (Number.isNaN(quantityN)) {
      alert("수량에 숫자를 입력하세요!", "Error");
      return;
    }
    if (type === "실재고 확인") {
      if (quantityN < 0) {
        alert("수량에 0 이상의 값을 입력하세요!", "Error");
        return;
      }
    } else {
      if (quantityN < 1) {
        alert("수량에 양수를 입력하세요!", "Error");
        return;
      }
    }
    if (!U.isProperEightDigitStringDate(date)) {
      alert("등록일에 적절한 날짜를 입력하세요!", "Error");
      return;
    }

    // int or float인 column 들에 대해 string or Localed string에서 int or float으로 변환 후 upload
    let dateN: number = parseInt(date);
    if (Number.isNaN(dateN)) dateN = 0;

    let obj: Object = {
      type: type,
      date: dateN,
      registrant: registrant,
      itemId: itemId,
      itemName: itemName,
      quantity: quantityN,
      note: note,
    };
    await uploadJson("/ct/uploadpartusage", obj).then((response_status: number) => {
      if (response_status === 0) {
        alert("Upload succeeded!", "Success");
        navigate(-1);
        return;
      } else {
        alert("Failed to upload!", "Error");
        return;
      }
    });
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>카트리지 입출고 등록</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div className={"flex-item2"}>
              <TextBox
                label="분류"
                readOnly={true}
                value={type}
                valueChangeEvent="keyup"
                onValueChange={onTypeTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="품명"
                readOnly={true}
                value={itemName}
                valueChangeEvent="keyup"
                onValueChange={onItemNameTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-containerH2"}>
              <div className={"flex-item4"}>
                <TextBox
                  label="수량"
                  value={quantity}
                  valueChangeEvent="keyup"
                  onValueChange={onQuantityTextBoxValueChanged}
                  width={145}
                  showClearButton={true}
                />
              </div>
            </div>

            <div className={"flex-item2"}>
              <TextBox label="등록일" value={date} valueChangeEvent="keyup" onValueChange={onDateTextBoxValueChanged} width={300} showClearButton={true} />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="등록자"
                readOnly={true}
                value={registrant}
                valueChangeEvent="keyup"
                onValueChange={onRegistrantTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="Part Number"
                readOnly={true}
                value={itemId}
                valueChangeEvent="keyup"
                onValueChange={onItemIdTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Note" value={note} valueChangeEvent="keyup" onValueChange={onNoteTextBoxValueChanged} width={300} showClearButton={true} />
            </div>
          </div>

          <div>
            <div className={"flex-containerV"}>
              <div className={"flex-item1"}>
                <Button text="Upload" onClick={onUploadButtonClicked} width={300} type="default" icon="upload" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
